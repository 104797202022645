import { useQuery, useLazyQuery } from "@apollo/client"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons"
import mapboxgl from "!mapbox-gl"
import React, { useEffect, useRef, useState } from "react"
import { Button } from "react-bootstrap"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Select from "react-select"
import styled from "styled-components"
import { LIST_COUNTRY_ACCOUNTS } from "../schemas/countryAccount"
import { LIST_LOCATIONS } from "../schemas/location"

mapboxgl.accessToken =
    "pk.eyJ1Ijoic2F0aW1vdG8iLCJhIjoiY2t6bzlpajQxMzV5MzJwbnI0bW0zOW1waSJ9.RXvZaqPLk3xNagAQ-BAfQg"

const MapContainer = styled.div`
    width: 100%;
    min-width: 280pt;
    height: auto;
    min-height: 100vh;
    background: #303030;
`

const Sidebar = styled.div`
    background-color: rgba(35, 55, 75, 0.9);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    margin: 12px;
    border-radius: 4px;
`

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? "#dd00ee" : "#008ae6"
    })
}

const MapPage = () => {
    const mapContainer = useRef(null)
    const map = useRef(null)
    const [lng, setLng] = useState(9.9282)
    const [lat, setLat] = useState(51.1902)
    const [zoom, setZoom] = useState(5.77)
    const [showSidebar, setShowSidebar] = useState(true)
    const [countryOptions, setCountryOptions] = useState([])
    const [countryCodeOptions, setCountryCodeOptions] = useState([])
    const [partyIdOptions, setPartyIdOptions] = useState([])
    const [selectedCountry, setSelectedCountry] = useState(null)
    const [selectedCountryCode, setSelectedCountryCode] = useState(null)
    const [selectedPartyId, setSelectedPartyId] = useState(null)
    const [selectedDate, setSelectedDate] = useState(new Date())

    const countryAccountsQuery = useQuery(LIST_COUNTRY_ACCOUNTS)
    const [listLocations, locationsQuery] = useLazyQuery(LIST_LOCATIONS)

    useEffect(() => {
        if (countryAccountsQuery.data) {
            setCountryOptions(
                countryAccountsQuery.data.listCountryAccounts.map(
                    (countryAccount) => {
                        return {
                            value: countryAccount.country,
                            label: countryAccount.country,
                            longitude: countryAccount.longitude,
                            latitude: countryAccount.latitude,
                            zoom: countryAccount.zoom
                        }
                    }
                )
            )
        }
    }, [countryAccountsQuery.data])

    useEffect(() => {
        if (locationsQuery.data) {
            setCountryCodeOptions(
                locationsQuery.data.listLocations
                    .map((location) => location.countryCode)
                    .filter(
                        (countryCode, index, arr) =>
                            countryCode && arr.indexOf(countryCode) === index
                    )
                    .map((countryCode) => {
                        return {
                            value: countryCode,
                            label: countryCode
                        }
                    })
            )
            setPartyIdOptions(
                locationsQuery.data.listLocations
                    .map((location) => location.partyId)
                    .filter(
                        (partyId, index, arr) =>
                            partyId && arr.indexOf(partyId) === index
                    )
                    .map((partyId) => {
                        return {
                            value: partyId,
                            label: partyId
                        }
                    })
            )

            map.current.getSource("locations-source").setData({
                type: "FeatureCollection",
                features: locationsQuery.data.listLocations.map((location) => {
                    return {
                        type: "Feature",
                        geometry: location.geom,
                        properties: {
                            ...location,
                            unixTimestamp: new Date(
                                location.addedDate
                            ).getTime()
                        }
                    }
                })
            })
        }
    }, [locationsQuery.data])

    useEffect(() => {
        if (map.current) {
            setSelectedCountryCode(null)
            setSelectedPartyId(null)

            if (selectedCountry) {
                listLocations({
                    variables: {
                        input: {
                            country: selectedCountry.value
                        }
                    }
                })

                map.current.setFilter("hightlighted-countries", [
                    "==",
                    ["get", "iso_3166_1_alpha_3"],
                    selectedCountry.value
                ])

                if (selectedCountry.zoom) {
                    map.current.flyTo({
                        center: [
                            selectedCountry.longitude,
                            selectedCountry.latitude
                        ],
                        zoom: selectedCountry.zoom
                    })
                }
            } else {
                map.current.setFilter("hightlighted-countries", null)
            }
        }
    }, [selectedCountry])

    useEffect(() => {
        if (map.current) {
            map.current.setFilter("locations-layer-gray", [
                "any",
                selectedDate
                    ? ["<", ["get", "unixTimestamp"], selectedDate.getTime()]
                    : true,
                selectedCountryCode
                    ? ["!=", ["get", "countryCode"], selectedCountryCode.value]
                    : true,
                selectedPartyId
                    ? ["!=", ["get", "partyId"], selectedPartyId.value]
                    : true
            ])

            map.current.setFilter("locations-layer-pink", [
                "all",
                selectedDate
                    ? [">=", ["get", "unixTimestamp"], selectedDate.getTime()]
                    : true,
                selectedCountryCode
                    ? ["==", ["get", "countryCode"], selectedCountryCode.value]
                    : true,
                selectedPartyId
                    ? ["==", ["get", "partyId"], selectedPartyId.value]
                    : true
            ])
        }
    }, [selectedCountryCode, selectedPartyId, selectedDate])

    useEffect(() => {
        if (map.current) return // initialize map only once

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: "mapbox://styles/satimoto/cla3w6uo3007l14nu1tcmgu2j",
            center: [lng, lat],
            zoom: zoom
        })

        map.current.on("move", () => {
            setLng(map.current.getCenter().lng.toFixed(4))
            setLat(map.current.getCenter().lat.toFixed(4))
            setZoom(map.current.getZoom().toFixed(2))
        })

        map.current.on("load", () => {
            map.current.addLayer(
                {
                    id: "hightlighted-countries",
                    source: {
                        type: "vector",
                        url: "mapbox://mapbox.country-boundaries-v1"
                    },
                    "source-layer": "country_boundaries",
                    type: "fill",
                    paint: {
                        "fill-color": "#008ae6",
                        "fill-opacity": 1
                    }
                },
                "country-boundaries"
            )

            map.current.setFilter("hightlighted-countries", [
                "==",
                ["get", "iso_3166_1_alpha_3"],
                "NONE"
            ])

            map.current.addSource("locations-source", {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: []
                }
            })

            map.current.addLayer({
                id: "locations-layer-gray",
                type: "circle",
                source: "locations-source",
                paint: {
                    "circle-radius": 4,
                    "circle-color": "#888888",
                    "circle-stroke-width": 1
                }
            })

            map.current.addLayer({
                id: "locations-layer-pink",
                type: "circle",
                source: "locations-source",
                paint: {
                    "circle-radius": 6,
                    "circle-color": "#b82cd1",
                    "circle-stroke-color": "white",
                    "circle-stroke-width": 1
                }
            })
        })
    }, [])

    const onButtonClick = () => {
        setShowSidebar(!showSidebar)
    }

    return (
        <div>
            {!countryAccountsQuery.loading && !countryAccountsQuery.error && (
                <Sidebar style={{ padding: "6px" }}>
                    {showSidebar ? (
                        <div style={{ display: "block" }}>
                            <div style={{ display: "flex" }}>
                                <Button onClick={onButtonClick}>
                                    <FontAwesomeIcon icon={faMinus} />
                                </Button>
                                <Select
                                    className="col-4 p-0"
                                    styles={customStyles}
                                    defaultValue={selectedCountry}
                                    onChange={setSelectedCountry}
                                    options={countryOptions}
                                />
                                <Select
                                    className="col-2 p-0"
                                    styles={customStyles}
                                    defaultValue={selectedCountryCode}
                                    onChange={setSelectedCountryCode}
                                    options={countryCodeOptions}
                                />
                                <Select
                                    className="col-2 p-0"
                                    styles={customStyles}
                                    defaultValue={selectedPartyId}
                                    onChange={setSelectedPartyId}
                                    options={partyIdOptions}
                                />
                                <DatePicker
                                    className="form-control"
                                    value={selectedDate}
                                    onChange={setSelectedDate}
                                />
                            </div>
                            Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
                        </div>
                    ) : (
                        <Button onClick={onButtonClick}>
                            <FontAwesomeIcon icon={faPlus} />
                        </Button>
                    )}
                </Sidebar>
            )}
            <MapContainer ref={mapContainer} />
        </div>
    )
}

export default MapPage
