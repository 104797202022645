import { gql } from "@apollo/client"

export const LIST_LOCATIONS = gql`
    query ListLocations($input: ListLocationsInput!) {
        listLocations(input: $input) {
            countryCode
            partyId
            geom
            addedDate
        }
    }
`
