import { gql } from "@apollo/client"

export const LIST_COUNTRY_ACCOUNTS = gql`
    query ListCountryAccounts {
        listCountryAccounts {
            country
            longitude
            latitude
            zoom
        }
    }
`
